import React, { useMemo } from 'react'
import { AccordionCard } from 'components/AccordionCard'
import {
  Contact,
  Calendar1,
  MapPin,
  Briefcase,
  CalendarX,
  CalendarSync,
} from 'lucide-react'
import { useShiftDetailContextValue } from '../context/ShiftDetailProvider'
import { useAppTheme, Text, Pluralize, Link, Box } from '@workwhile/ui'
import { formatInTimeZone } from 'date-fns-tz'
import { getDurationInHours, getLocalTimezone } from 'lib/time'
import { FlexibleInfoTable } from './FlexibleInfoTable'
import {
  differenceInHours,
  differenceInMinutes,
  formatDistanceStrict,
  formatDistanceToNowStrict,
  formatDuration,
} from 'date-fns'
import { getAddressString } from 'lib/address'
import { formatSupervisors } from '../utils/formatSupervisors'
import { PositionRequirementsReferencePopover } from './PositionRequirementsReferencePopover'

export const GeneralDetails = () => {
  const { space, colors } = useAppTheme()
  const {
    state: {
      position,
      startsAt,
      endsAt,
      lunchBreak,
      lastUpdated,
      location,
      locationless,
      supervisors,
      createdAt,
      cancelledAt,
      company,
    },
  } = useShiftDetailContextValue()

  const timezone = location.address.timezone ?? getLocalTimezone()
  const startTime = formatInTimeZone(startsAt, timezone, 'h:mm a')
  const endTime = formatInTimeZone(endsAt, timezone, 'h:mm a z')
  const durationInHours = getDurationInHours(startsAt, endsAt)
  const duration = formatDuration({ hours: durationInHours })
  const day = formatInTimeZone(startsAt, timezone, 'EEEE, MMMM d')
  const dayDistance = formatDistanceToNowStrict(startsAt, {
    addSuffix: true,
  })

  const createdAtDay = formatInTimeZone(
    createdAt,
    timezone,
    'EEEE, MMMM d, h:mm a z'
  )
  const createdAtDayDistance = formatDistanceToNowStrict(createdAt, {
    addSuffix: true,
  })

  const createdAtStartsAtDistance = formatDistanceStrict(createdAt, startsAt, {
    addSuffix: false,
  })

  const cancelledAtDay = cancelledAt
    ? formatInTimeZone(cancelledAt, timezone, 'EEEE, MMMM d, h:mm a z')
    : null

  const cancelledAtDayDistance = cancelledAt
    ? formatDistanceToNowStrict(cancelledAt, {
        addSuffix: true,
      })
    : null

  const cancelledAtStartsAtDistance = cancelledAt
    ? formatDistanceStrict(cancelledAt, startsAt, {
        addSuffix: false,
      })
    : null

  const lastUpdatedAt = useMemo(() => {
    if (!lastUpdated) return null
    const lastUpdatedDay = formatInTimeZone(
      lastUpdated.updatedAt,
      timezone,
      'EEEE, MMMM d, h:mm a z'
    )

    const lastUpdatedDayDistance = formatDistanceToNowStrict(
      lastUpdated.updatedAt,
      {
        addSuffix: true,
      }
    )

    if (!lastUpdated.updatedByUser) {
      return (
        <FlexibleInfoTable Icon={CalendarSync}>
          <Text fontSize={2} maxWidth="100%">
            Last updated at {lastUpdatedDay} ({lastUpdatedDayDistance})
          </Text>
        </FlexibleInfoTable>
      )
    }

    return (
      <FlexibleInfoTable Icon={CalendarSync}>
        <Text fontSize={2} maxWidth="100%">
          Last updated at {lastUpdatedDay} ({lastUpdatedDayDistance})
        </Text>
        <Text fontSize={2} maxWidth="100%">
          by {lastUpdated.updatedByUser.name} (
          <Link to={`/users/${lastUpdated.updatedByUser.id}`} target="_blank">
            {lastUpdated.updatedByUser.id}
          </Link>
          )
        </Text>
      </FlexibleInfoTable>
    )
  }, [lastUpdated])

  const lunchBreakText = useMemo(() => {
    if (!lunchBreak || lunchBreak.durationInMinutes === 0)
      return 'No lunch break set'

    if (lunchBreak.type === 'paid') {
      return `Paid break of ${lunchBreak.durationInMinutes} mins`
    }

    return `Unpaid break of ${lunchBreak.durationInMinutes} mins`
  }, [lunchBreak])

  return (
    <AccordionCard
      title="General details"
      size="fit"
      contentWrapperProps={{
        display: 'flex',
        flexDirection: 'column',
        gap: space[2],
      }}
    >
      <FlexibleInfoTable Icon={Briefcase}>
        <Text>Shift type: {company.type}</Text>
      </FlexibleInfoTable>

      <FlexibleInfoTable Icon={Contact}>
        <Text>
          {position.name} ({position.id}){' '}
          <PositionRequirementsReferencePopover position={position} />
        </Text>
      </FlexibleInfoTable>
      <FlexibleInfoTable Icon={Calendar1}>
        <Text fontSize={2}>
          {day} ({dayDistance})
        </Text>
        <Text fontSize={2}>
          {startTime} - {endTime} ({duration})
        </Text>
        <Text fontSize={2} mt={1}>
          {lunchBreakText}
        </Text>
      </FlexibleInfoTable>
      <FlexibleInfoTable Icon={MapPin}>
        <Text fontSize={2}>
          {locationless ? 'Locationless' : getAddressString(location.address)}
        </Text>
        <Text fontSize={2} mt={1}>
          <Pluralize
            oneText="Supervisor"
            manyText="Supervisors"
            hideCount
            count={supervisors.length}
          />
          : {formatSupervisors(supervisors)}
        </Text>
      </FlexibleInfoTable>
      <FlexibleInfoTable Icon={Calendar1}>
        <Text fontSize={2} maxWidth="100%">
          Posted on {createdAtDay} ({createdAtDayDistance})
        </Text>
        <Text fontSize={2} maxWidth="100%">
          {createdAtStartsAtDistance} notice
        </Text>
      </FlexibleInfoTable>
      {lastUpdatedAt}
      {cancelledAt ? (
        <FlexibleInfoTable Icon={CalendarX} iconColor={colors.errors[200]}>
          <Text fontSize={2} maxWidth="100%" color={colors.errors[200]}>
            Cancelled on {cancelledAtDay} ({cancelledAtDayDistance})
          </Text>
          <Text fontSize={2} maxWidth="100%" color={colors.errors[200]}>
            {cancelledAtStartsAtDistance} notice
          </Text>
        </FlexibleInfoTable>
      ) : null}
    </AccordionCard>
  )
}
