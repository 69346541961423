import { useQuery, useQueryClient } from '@tanstack/react-query'
import { tierEligibilitiesByShiftIdKey } from './keys'
import { getTierEligibilitiesByShiftId } from 'api/shifts/getTierEligibilitiesByShiftId'

export const useTierEligibilitiesByShiftIdQuery = (shiftId: number) => {
  return useQuery({
    queryKey: tierEligibilitiesByShiftIdKey(shiftId),
    queryFn: () => getTierEligibilitiesByShiftId(shiftId),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })
}

export const usePrefetchTierEligibilitiesByShiftIdQuery = (shiftId: number) => {
  const queryClient = useQueryClient()
  return () => {
    queryClient.prefetchQuery({
      queryKey: tierEligibilitiesByShiftIdKey(shiftId),
      queryFn: () => getTierEligibilitiesByShiftId(shiftId),
    })
  }
}
