import { AccordionCard } from 'components/AccordionCard'
import React, { useMemo } from 'react'
import { useShiftDetailContextValue } from '../context/ShiftDetailProvider'
import { InfoTable } from 'components/InfoTable'
import { formatCurrency } from 'lib/currency'
import { ShiftBonus } from '../types'
import { Box, useAppTheme } from '@workwhile/ui'
import { differenceInMinutes, formatDuration } from 'date-fns'
import { getDurationInHours } from 'lib/time'

const getBonusLabel = (bonus: ShiftBonus) => {
  if (bonus.isDynamicBonus) {
    return 'Dynamic Bonus'
  }

  if (bonus.isNewWorkerBonus) {
    return 'New Worker Bonus'
  }

  if (bonus.companyPaid) {
    return 'Company Bonus'
  }

  return 'WorkWhile Bonus'
}

export const Pay = () => {
  const { space } = useAppTheme()

  const {
    state: {
      payRate,
      payLumpSum,
      shiftBonuses,
      workersNeeded,
      startsAt,
      endsAt,
    },
  } = useShiftDetailContextValue()

  const durationInHours = getDurationInHours(startsAt, endsAt)
  const formattedDuration = formatDuration({ hours: durationInHours })

  const basePay = useMemo(() => {
    if (typeof payRate === 'number') {
      return payRate * durationInHours
    }

    if (typeof payLumpSum === 'number') {
      return payLumpSum
    }

    return 0
  }, [payLumpSum, payRate, shiftBonuses])

  const basePayWithBonuses = useMemo(() => {
    const bonusTotal = shiftBonuses?.reduce(
      (acc, bonus) => acc + bonus.amount,
      0
    )

    return basePay + bonusTotal
  }, [basePay, shiftBonuses])

  const totalPay = useMemo(() => {
    return basePayWithBonuses * workersNeeded
  }, [basePayWithBonuses, durationInHours, workersNeeded])

  return (
    <AccordionCard
      title="Pay"
      size="fit"
      contentWrapperProps={{
        display: 'flex',
        flexDirection: 'column',
        width: '376px',
      }}
    >
      <InfoTable
        style={{
          width: 'auto',
          borderSpacing: `0 ${space[2]}`,
          marginTop: `-${space[2]}`,
          marginBottom: `-${space[2]}`,
        }}
      >
        {typeof payRate === 'number' ? (
          <InfoTable.Row
            label="Pay rate (per hour, per worker)"
            value={formatCurrency(payRate)}
          />
        ) : null}

        {payLumpSum ? (
          <InfoTable.Row
            label="Pay lump sum"
            value={formatCurrency(payLumpSum)}
          />
        ) : null}

        {shiftBonuses?.map((bonus) => {
          return (
            <InfoTable.Row
              key={bonus.amount}
              label={getBonusLabel(bonus)}
              value={formatCurrency(bonus.amount)}
            />
          )
        })}
      </InfoTable>
      <Box height={2} backgroundColor="neutrals.100" width="100%" my={3} />
      <InfoTable
        style={{
          width: 'auto',
          borderSpacing: `0 ${space[2]}`,
          marginTop: `-${space[2]}`,
          marginBottom: `-${space[2]}`,
        }}
      >
        {typeof payRate === 'number' ? (
          <InfoTable.Row label="Base Pay" value={formatCurrency(basePay)} />
        ) : null}

        {shiftBonuses?.length > 0 ? (
          <InfoTable.Row
            label={`Base Pay + ${shiftBonuses?.length === 1 ? 'Bonus' : 'Bonuses'}`}
            value={formatCurrency(basePayWithBonuses)}
          />
        ) : null}

        <InfoTable.Row
          label={`Total Pay (${formattedDuration}, ${workersNeeded} workers)`}
          value={formatCurrency(totalPay)}
        />
      </InfoTable>
    </AccordionCard>
  )
}
