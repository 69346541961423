type FormatNumberOptions = Intl.NumberFormatOptions

const percentPreset: FormatNumberOptions = {
  style: 'decimal',
  maximumFractionDigits: 2,
}

export const formatNumber = (value: number, options?: FormatNumberOptions) => {
  return new Intl.NumberFormat('en-US', {
    ...percentPreset,
    ...options,
  }).format(value)
}

export const getSafeNumber = (value: number) => {
  if (Number.isNaN(value)) {
    return 0
  }

  return value
}

export const roundNumber = (num: number, decimals: number): number => {
  return Number(Math.round(Number(num + 'e' + decimals)) + 'e-' + decimals)
}
